<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
        >
            <div
                class="header"
            >
                <span class="title">{{ $t('contract_diary.title') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 editor-form pb-4">
            <b-tabs fill v-model="currentTabIndex">
                <b-tab :title="$t('contract_diary.new')" active :title-link-class="linkTabClass(0)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : currentTabIndex === 0}">
                                {{ $t('contract_diary.new') }}
                            </div>
                        </b-row>
                    </template>

                    <contract-diaries-search

                    />
                </b-tab>
                <b-tab :title="$t('contracts.ely_reports')" :title-link-class="linkTabClass(1)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : currentTabIndex === 1}">
                                {{ $t('contracts.ely_reports') }}
                            </div>
                        </b-row>
                    </template>
                <work-diary-search
                    @dateChange="setDate"
                    @contractSelected="setContract"
                    @search="fetchDiaries"/>
                <contract-work-diary
                    :contract="contract"
                    :date="date"
                    @submitReport="submitDiary"/>
                </b-tab>
                <b-tab :title="$t('contracts.city_work_diary')" :title-link-class="linkTabClass(2)">
                    <template #title>
                        <b-row class="nopads text-center">
                            <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : currentTabIndex === 2}">
                                {{ $t('contracts.city_work_diary') }}
                            </div>
                        </b-row>
                    </template>
                    <work-diary-search
                        @dateChange="setDate"
                        @contractSelected="setContract"
                    />
                    <city-contract-diary
                        :contract="contract"
                        :date="date"
                    />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import {workManagementHelper} from '@/components/mixins/WorkManagementMixin'
import ContractDiariesSearch from "@/components/contractreport/ContractDiariesSearch.vue";
import ContractWorkDiary from "@/components/contract/external/ContractWorkDiary.vue";
import {EventBus} from "@/event-bus";
import workDiarySearch from "@/components/contractreport/WorkDiarySearch.vue";
import CityContractDiary from "@/components/contractreport/CityContractDiary";

export default {
    name: "contract-diaries",
    components: {ContractWorkDiary, ContractDiariesSearch, workDiarySearch, CityContractDiary},
    mixins: [timeUtils, restApi, workManagementHelper],
    data: function () {
        return {
            lastSearchedParams: null,
            results: [],
            loading: false,
            currentTabIndex: 0,
            contract: null,
            date: null,
        }
    },
    methods: {
        setContract(contract) {
            this.contract = contract
        },

        setDate(date) {
            this.date = new Date(date)
        },

        fetchDiaries: function (params) {
            this.results = []
            if(!params || !params.contract) {
                EventBus.$emit('show-alert', this.$t('contract_diary.invalid_contract'))
                return undefined
            }
            let mappedParams = {
                contract: params.contract ? params.contract.id : null,
                from: params.startDate ? this.getDateIsoString(new Date(params.startDate)) : null,
                to: params.endDate ? this.getDateIsoString(new Date(params.endDate)) : null,
            }
            this.lastSearchedParams = params
            this.loading = true
            this.restFetchParams(this.contractDiaryUrl, mappedParams, this.handleResponse)

        },
        handleResponse: function (response) {
            if (response && response.data) {
                this.results = response.data
            }
            this.loading = false
        },

        submitDiary(diaryData) {
            if (diaryData) {
                this.loading = true
                const params = {
                    contract: this.contract.id,
                    data: diaryData
                }
                this.restAdd(this.extWorkDiaryUrl, params, this.handleReportOk, this.handleReportFail)
            }
        },
        handleReportOk() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('contracts.external.report_success'), 'success')
            this.equipmentTypes = []
            this.roads = []
        },
        handleReportFail() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('contracts.external.report_failed'))
        },

        linkTabClass(index) {
            if (this.currentTabIndex === index) {
                return ['tab-title', 'tab-title-active']
            } else {
                return ['tab-title']
            }
        }

    }
}
</script>
